@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply h-auto w-full;
}

body {
  @apply bg-contain;
  background-position: top right;
  background-repeat: no-repeat;
  background-color: #1D2D4A;
}

/*  forms  */
.form-input[type="text"],
.form-input[type="email"],
.form-input[type="number"] {
  border: 0;
  border-bottom: 1px solid #1b3668;
  color: #333;
  padding-left: 0;
}

.form-input[type="text"]:active,
.form-input[type="email"]:active,
.form-input[type="number"]:active,
.form-input[type="text"]:focus,
.form-input[type="email"]:focus,
.form-input[type="number"]:focus {
  border: 0;
  outline: 0;
  box-shadow: none;
  border-bottom: 1px solid #1b3668;
}

.form-input::placeholder {
  color: #333;
  font-size: 14px;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

.terms-frame {
  width:100%;
  height: 100%;
}